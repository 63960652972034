import React from 'react'
import SEO from '../components/seo'
import Services from '../components/services'
import Layout from '../Layout'

const ServicesPage = () => {
  return (
    <Layout>
      <SEO
        title="M&S Reliable Construction Services | Flooring, Tiling, Painting, Dry Wall, Masonry, Mudding and more!"
        description="Renovation Services provided by M&S Reliable Construction in British Columbia."
        lang="en"
      />
      <Services />
    </Layout>
  )
}

export default ServicesPage
