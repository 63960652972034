import React from 'react'
import { Landing } from '../landing'
import { useStaticQuery, graphql } from 'gatsby'
import { Split } from '../split'

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Home_Renovations_(Unsplash).jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      flooringImage: file(relativePath: { eq: "flooring.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            base64
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
          }
        }
      }
      masonryImage: file(relativePath: { eq: "masonry.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            base64
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
          }
        }
      }
      paintingImage: file(relativePath: { eq: "painting.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            base64
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
          }
        }
      }
      tilingBeforeImage: file(relativePath: { eq: "tiling-before.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            base64
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
          }
        }
      }
      tilingAfterImage: file(relativePath: { eq: "tiling-after.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            base64
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
          }
        }
      }
      tilingImage1: file(relativePath: { eq: "tiling.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            base64
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
          }
        }
      }
      tilingImage2: file(relativePath: { eq: "tiling2.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            base64
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
          }
        }
      }
      drywallBeforeImage: file(relativePath: { eq: "drywall-before.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            base64
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
          }
        }
      }
      drywallAfterImage: file(relativePath: { eq: "drywall-after.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            base64
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
          }
        }
      }
      framingBeforeImage: file(relativePath: { eq: "framing-before.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            base64
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
          }
        }
      }
      framingAfterImage: file(relativePath: { eq: "framing-after.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            base64
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
          }
        }
      }
      drywallImage: file(relativePath: { eq: "drywall.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            base64
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
          }
        }
      }
    }
  `)

  return (
    <>
      <Landing
        copy={
          <h1>
            Reliable <br /> Construction <br /> Services
          </h1>
        }
        data={data?.placeholderImage?.childImageSharp?.fluid}
      />
      <Split
        images={[data?.flooringImage?.childImageSharp?.fluid]}
        title={'Flooring'}
        copy={
          'Different kinds of flooring materials have their own way of adding to the atmosphere of ones home. At M&S Reliable, we help you select between a wide range of flooring options to fit your needs and budget. We are a group of professionals that can handle any flooring job, big or small. Flooring we specialize in range from Hardwood flooring, Laminate Flooring, and Vinyl Flooring.'
        }
      />
      <Split
        images={[
          data?.tilingBeforeImage?.childImageSharp?.fluid,
          data?.tilingImage1?.childImageSharp?.fluid,
          data?.tilingImage2?.childImageSharp?.fluid,
        ]}
        title={'Tiling'}
        copy={
          'We can help you decide which kinds of tiling are best suited for your home. The choices in tiling can be overwhelming, so hire professionals that can help with decision making and installation! We cover all sort of tiling jobs such as ceramic tiling, porcelain tiling, glass tiling, cement tiling, marble tiling, mosaic tiling, granite tiling, limestone tiling, travertine tiling, querry tiling, metal tiling, and resin tiling! Please reach out for a quote to see if our services match your needs.'
        }
        reverse
      />
      <Split
        images={[data?.paintingImage?.childImageSharp?.fluid]}
        title={'Painting'}
        copy={
          'Need fast and reliable painting for your home? We can help! We provide cheap painting jobs anywhere from offices to home decoration. We have experts that know how to make just the right choices for you!'
        }
      />
      <Split
        images={[data?.drywallBeforeImage?.childImageSharp?.fluid, data?.drywallAfterImage?.childImageSharp?.fluid]}
        title={'Dry Wall'}
        copy={
          'Dry wall installation can be messy. It is necessary to provide the finishing touches to your new and improved renovated atmosphere! Our dry walling services are quick, reliable, and clean. We make sure never to leave a mess and to always provide the best quality of service.'
        }
        reverse
      />
      <Split
        images={[data?.masonryImage?.childImageSharp?.fluid]}
        title={'Masonry'}
        copy={
          'We provide high quality stone, clay, brick, and concrete block work. We pride ourselves in our Brick & Stone Masonry Construction capabilities. We also provide Concrete Masonry and Composite Masonry. '
        }
      />
      <Split
        images={[data?.framingBeforeImage?.childImageSharp?.fluid, data?.framingAfterImage?.childImageSharp?.fluid]}
        title={'Framing'}
        copy={'Structural framing done by professionals. '}
        reverse
      />
    </>
  )
}

export default Services
