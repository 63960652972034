import React from 'react'
import styled from 'styled-components'
import { ImageCarousel } from './imageSlider'

export const Split = ({ copy, title, images, reverse }) => {
  if (reverse) {
    return (
      <StyledSplit reverse id={title}>
        <ImageCarousel images={images} />
        <div className="copy">
          <h2>{title}</h2>
          <p>{copy}</p>
        </div>
      </StyledSplit>
    )
  }

  return (
    <StyledSplit id={title}>
      <div className="copy">
        <h2>{title}</h2>
        <p> {copy}</p>
      </div>
      <ImageCarousel images={images} />
    </StyledSplit>
  )
}

const StyledSplit = styled.section`
  display: flex;
  grid-column: 1 / 4;
  width: 100%;

  .copy {
    flex: 1;
    width: 100%;
    padding: 4rem;

    @media (max-width: 775px) {
      padding: 2rem 1rem;
    }
    display: flex;
    flex-direction: column;
  }

  h2 {
    text-align: center;
    padding-bottom: 2rem;
    padding-top: 50px;
  }

  @media (max-width: 640px) {
    flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
  }
`
