import React from 'react'
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import styled from 'styled-components'
import 'pure-react-carousel/dist/react-carousel.es.css'
import Img, { FluidObject } from 'gatsby-image'

interface ImageCarousel {
  images: FluidObject[]
}

export const ImageCarousel = ({ images }: ImageCarousel): JSX.Element => {
  return (
    <StyledCarousel>
      <CarouselProvider
        naturalSlideWidth={600}
        naturalSlideHeight={1000}
        totalSlides={images.length}
        isPlaying
        infinite
        interval={3000}
      >
        <Slider className="slider">
          {images.map((image, index) => (
            <SlideComponent index={index} key={index} data={image} />
          ))}
        </Slider>
      </CarouselProvider>
    </StyledCarousel>
  )
}

const SlideComponent = ({ index, data }) => {
  return (
    <Slide index={index}>
      <div className="slider">
        <Img fluid={data} className="image" />
      </div>
    </Slide>
  )
}

const StyledCarousel = styled.section`
  min-width: 600px;
  min-height: 600px;
  flex: 1;
  grid-column: 1 / 5;
  min-width: 50%;
  max-height: 60vh;
  flex: 1;

  @media (max-width: 650px) {
    min-width: 100%;
  }

  .slider {
    text-align: center;
    width: 100%;
    border-radius: 0.5rem;
    max-height: 600px;
  }

  .horizontalSlider___281Ls {
  }

  .image {
    border-radius: 0.5rem;
    height: 100%;
  }

  .carousel__inner-slide {
    height: 600px;
  }

  .carousel {
    height: 100%;

    .carousel__slider .slider {
      height: 100% !important;
    }
  }
`
